import clsx from "clsx";
import { isEmpty, isNil } from "lodash";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { EMPTY_TEXTAREA_VALUE } from "common/form/DescriptionForm/DescriptionForm";
import { twClass } from "utils/twClass";
import { DangerousHTML } from "../DangerousHTML/DangerousHTML";

type Props = {
  className?: string;
  html: string | null;
  placeholderHtml?: string;
};

export const TextViewer = ({
  className,
  html,
  placeholderHtml,
}: Props): JSX.Element => {
  const isEmptyHtml =
    isNil(html) || isEmpty(html) || html === EMPTY_TEXTAREA_VALUE;
  const shownValue = isEmptyHtml
    ? placeholderHtml
    : sanitizeHtml(html, {
        allowedAttributes: { img: ["src", "class"], span: ["style"] },
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
      });
  return (
    <div className={twClass("rounded", className)} data-fs="hide">
      <DangerousHTML
        className={clsx("break-words", {
          "text-slate-500": isEmptyHtml,
          "text-slate-800": !isEmptyHtml,
        })}
        html={shownValue ?? ""}
      />
    </div>
  );
};
