import {
  GoalUpdateCycle,
  namedOperations,
  Objective,
  PerdooApiObjectiveStageChoices,
  ProgressDriver,
  UpsertObjectiveMutationInput,
  useUpsertObjectiveMutationMutation,
} from "@graphql";
import { Derive } from "@shoooe/derive";
import { useIntl } from "react-intl";
import { getOwnershipInfo } from "common/goal/GoalOwners/GoalOwners.utils";
import { useCompany } from "hooks/useCompany/useCompany";
import { handleErrors } from "utils/graphql/handleErrors";
import { toast } from "utils/toastr";

type ObjectiveParam = Derive<
  Objective,
  {
    goalUpdateCycle: true;
    id: true;
    lead: {
      id: true;
    };
    private: true;
    progressDriver: true;
    stage: true;
    timeframe: {
      id: true;
    };
  }
>;

type BaseUpsertInput = {
  input: UpsertObjectiveMutationInput;
  successMessage: string;
};

export type ObjectiveAlignmentInput = Pick<
  UpsertObjectiveMutationInput,
  "kpi" | "parent" | "strategicPillar"
>;

export const useInlineObjectiveActions = (objective: ObjectiveParam) => {
  const intl = useIntl();
  const company = useCompany();
  const [upsertObjective] = useUpsertObjectiveMutationMutation({
    refetchQueries: [
      namedOperations.Query.getProfileObjectives,
      namedOperations.Query.getObjectiveTimeline,
      namedOperations.Query.getTimeframeOkrsCount,
    ],
  });

  const baseUpsert = async (input: BaseUpsertInput) => {
    const response = await upsertObjective({
      variables: {
        input: {
          ...input.input,
          id: objective.id,
        },
      },
    });

    const { hasError } = handleErrors(
      response,
      response.data?.upsertObjective?.errors
    );
    if (hasError) return false;

    toast.success(input.successMessage);
    return true;
  };

  const setProgressDriver = async (value: ProgressDriver | null) => {
    if (!value || value === objective.progressDriver) return false;
    return await baseUpsert({
      input: { progressDriver: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Progress driver updated!",
        id: "MCwt7Q",
      }),
    });
  };

  const setUpdateFrequency = async (value: GoalUpdateCycle | null) => {
    if (!value || value === objective.goalUpdateCycle) return false;
    return await baseUpsert({
      input: { goalUpdateCycle: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Update frequency updated!",
        id: "Dbcl+C",
      }),
    });
  };

  const setStage = async (value: PerdooApiObjectiveStageChoices | null) => {
    if (!value || value === objective.stage) return false;
    return await baseUpsert({
      input: { stage: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Stage updated!",
        id: "CrBuzU",
      }),
    });
  };

  const setVisibility = async (isPrivate: boolean) => {
    if (isPrivate === objective.private) return false;
    return await baseUpsert({
      input: { private: isPrivate },
      successMessage: intl.formatMessage({
        defaultMessage: "Visibility updated!",
        id: "ofSvWp",
      }),
    });
  };

  const setLead = async (value: string | null) => {
    if (!value || value === objective.lead?.id) return false;
    return await baseUpsert({
      input: { lead: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Objective lead updated",
        id: "0eFSdK",
      }),
    });
  };

  const setAlignment = (input: ObjectiveAlignmentInput) =>
    baseUpsert({
      input,
      successMessage: intl.formatMessage({
        defaultMessage: "Alignment updated!",
        id: "Lt6E2E",
      }),
    });

  const setTimeframe = async (value: string) => {
    if (value === objective.timeframe.id) return false;
    return await baseUpsert({
      input: { timeframe: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Timeframe updated!",
        id: "FaRwxq",
      }),
    });
  };

  const setContributors = (value: string[]) =>
    baseUpsert({
      input: { contributors: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Objective contributors updated!",
        id: "I3P4Ug",
      }),
    });

  const setTags = (value: string[]) =>
    baseUpsert({
      input: { tags: value },
      successMessage: intl.formatMessage({
        defaultMessage: "Objective tags updated!",
        id: "+qBg8E",
      }),
    });

  const setOwners = async (value: string[]) => {
    const { groups, isCompanyGoal } = getOwnershipInfo(value, company.id);
    return await baseUpsert({
      input: { groups, isCompanyGoal },
      successMessage: intl.formatMessage({
        defaultMessage: "Objective owners updated!",
        id: "1n0YZq",
      }),
    });
  };

  return {
    setAlignment,
    setContributors,
    setLead,
    setOwners,
    setProgressDriver,
    setStage,
    setTags,
    setTimeframe,
    setUpdateFrequency,
    setVisibility,
  };
};
