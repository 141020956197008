import { StatusBreakdownType, StatusBreakdownWithDeltaType } from "@graphql";

export const aStatusBreakdown = (
  overrides?: Partial<StatusBreakdownType>
): StatusBreakdownType => ({
  __typename: "StatusBreakdownType",
  ACCOMPLISHED: overrides?.ACCOMPLISHED ?? 0,
  MISSED: overrides?.MISSED ?? 0,
  NEEDS_ATTENTION: overrides?.NEEDS_ATTENTION ?? 0,
  NO_STATUS: overrides?.NO_STATUS ?? 0,
  OFF_TRACK: overrides?.OFF_TRACK ?? 0,
  ON_TRACK: overrides?.ON_TRACK ?? 0,
  POSTPONED: overrides?.POSTPONED ?? 0,
});

export const aStatusBreakdownWithDelta = (
  overrides?: Partial<StatusBreakdownWithDeltaType>
): StatusBreakdownWithDeltaType => ({
  __typename: "StatusBreakdownWithDeltaType",
  ACCOMPLISHED: overrides?.ACCOMPLISHED ?? 0,
  ACCOMPLISHED_DELTA: overrides?.ACCOMPLISHED_DELTA ?? 0,
  ACHIEVED: overrides?.ACHIEVED ?? 0,
  ACHIEVED_DELTA: overrides?.ACHIEVED_DELTA ?? 0,
  CANCELLED: overrides?.CANCELLED ?? 0,
  CANCELLED_DELTA: overrides?.CANCELLED_DELTA ?? 0,
  HEALTHY: overrides?.HEALTHY ?? 0,
  HEALTHY_DELTA: overrides?.HEALTHY_DELTA ?? 0,
  MISSED: overrides?.MISSED ?? 0,
  MISSED_DELTA: overrides?.MISSED_DELTA ?? 0,
  NEEDS_ATTENTION: overrides?.NEEDS_ATTENTION ?? 0,
  NEEDS_ATTENTION_DELTA: overrides?.NEEDS_ATTENTION_DELTA ?? 0,
  NO_STATUS: overrides?.NO_STATUS ?? 0,
  NO_STATUS_DELTA: overrides?.NO_STATUS_DELTA ?? 0,
  OFF_TRACK: overrides?.OFF_TRACK ?? 0,
  OFF_TRACK_DELTA: overrides?.OFF_TRACK_DELTA ?? 0,
  ON_TRACK: overrides?.ON_TRACK ?? 0,
  ON_TRACK_DELTA: overrides?.ON_TRACK_DELTA ?? 0,
  PARTIALLY_ACHIEVED: overrides?.PARTIALLY_ACHIEVED ?? 0,
  PARTIALLY_ACHIEVED_DELTA: overrides?.PARTIALLY_ACHIEVED_DELTA ?? 0,
  POSTPONED: overrides?.POSTPONED ?? 0,
  POSTPONED_DELTA: overrides?.POSTPONED_DELTA ?? 0,
  UNHEALTHY: overrides?.UNHEALTHY ?? 0,
  UNHEALTHY_DELTA: overrides?.UNHEALTHY_DELTA ?? 0,
});
